<template>
    <div>
        <v-container fluid fill-height style="height:99vh">
            <v-layout text-center class="d-flex align-center justify-center" row wrap justify-space-around>
                <v-flex xs8>
                    <img :src="svg" alt=""/>
                    <h1>کێشەم هەیە</h1>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
    export default {
        name: 'NotFoundPage',
        data() {
            return {
                svg:require('../assets/429.svg')
            }
        }
    }
</script>