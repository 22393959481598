<template>
    <div class="view-page">
        <v-app-bar v-if="!loading && !not_found && !err_page && $device.mobile" fixed  color="primary" dark>
            <v-icon size="30" class="ml-3" v-if="$device.ios">fab fa-app-store-ios</v-icon>
            <v-icon size="30" class="ml-3" v-if="$device.android">fab fa-google-play</v-icon>
            <span v-if="$device.ios">
                شایەن دابەزێنە لە ئەپستۆر
            </span>
            <span v-if="$device.android">
                شایەن دابەزێنە لە پلەیستۆر
            </span>
            <v-spacer></v-spacer>
            <v-btn color="white" v-if="$device.ios" href="#" class="black--text" rounded>
                باشە گیان
            </v-btn>
            <v-btn color="white" v-if="$device.android" href="#" class="black--text" rounded>
                باشە گیان
            </v-btn>
        </v-app-bar>
        <ItemLoading v-if="loading" />
        <ErrorPage v-else-if="!loading && err_page" />
        <NotFoundPage v-else-if="!loading && not_found" />
        <v-container class="mt-0" v-else-if="!loading && !not_found && !err_page">
            <v-layout row wrap justify-space-around>
                <v-flex xs12 lg6 xl6 md6 sm12>
                    <v-carousel class="pa-1" :show-arrows="false" hide-delimiters
                    progress
            height="300px">
                        <v-carousel-item v-for="(i,index) in item.item_images" :key="index">
                            <v-img height="300px" :src="$api+'/uploads/'+i.item_image_path" cover :aspect-ratio="1"
                                class="mx-auto rounded-lg mx-1" />
                        </v-carousel-item>
                    </v-carousel>
                    <v-card flat class="mx-4">
                        <v-card-title>
                            <h1 style="font-size:x-large" v-html="item.item_name"></h1>
                        </v-card-title>
                        <v-card-subtitle class="pt-1">
                            <v-btn color="black" target="_blank"
                                :href="'https://www.google.com/maps/dir/?api=1&destination=' + item.item_lat + ',' + item.item_long"
                                class="px-0" text x-small>
                                <v-icon size="20">fas fa-map-pin</v-icon> {{item.item_location}}
                            </v-btn>
                        </v-card-subtitle>
                        <v-card-text>
                            {{item.item_description}}
                            <br>
                            <span class="ml-1 blue--text" v-for="(i, index) in item.item_badges"
                                :key="index">#{{replaceSpaceWithUnderScore(i.badge_name)}}</span>
                            <v-divider class="my-4"></v-divider>
                            <v-container>
                                <v-layout row wrap my-4>
                                    <v-flex xs4>
                                        <v-card height="100" class=" rounded-lg" outlined>
                                            <v-container fill-height fluid class="text-center">
                                                <v-row align="center" justify="center">
                                                    <v-col>دەنگدەر</v-col>
                                                </v-row>
                                                <v-row align="center" class="mt-n3" justify="center">
                                                    <v-col>
                                                        <h1 style="font-size:x-large">
                                                            {{changeToK(item.item_total_review , 0)}}
                                                        </h1>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-card height="100" class="mx-1 rounded-lg" outlined>
                                            <v-container fill-height fluid class="text-center">
                                                <v-row align="center" justify="center">
                                                    <v-col>هەڵسەنگاند</v-col>
                                                </v-row>
                                                <v-row align="center" class="mt-n3" justify="center">
                                                    <v-col>
                                                        <h1 class="pt-3" style="font-size:x-large">
                                                            {{item.avg_rate}}⭐
                                                        </h1>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-card height="100" class=" rounded-lg" outlined>
                                            <v-container fill-height fluid class="text-center">
                                                <v-row align="center" justify="center">
                                                    <v-col>دڵخوازکراو</v-col>
                                                </v-row>
                                                <v-row align="center" class="mt-n3" justify="center">
                                                    <v-col>
                                                        <h1 style="font-size:x-large">
                                                            {{item.item_total_fav}} ❤️
                                                        </h1>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-divider class="my-4"></v-divider>
                            <v-card-title primary-title>
                                    ڕای خەڵک
                            </v-card-title>
                        </v-card-text>
                        <v-card-text>
                            <v-list three-line class="mx-0 px-0">
                                <v-list-item @click="openReplies(i.comment_id)" v-for="(i, index) in item.comments"
                                    :key="index">
                                    <v-list-item-avatar>
                                        <v-img :src="i.user_profile_picture" v-if="i.user_profile_picture"></v-img>
                                        <v-avatar size="100" v-else color="primary" class="white--text">
                                            {{i.user_display_name[0]}}
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{i.user_display_name}}
                                        </v-list-item-title>
                                        <span>
                                            {{truncateText(i.comment_text , 90).text}}
                                        </span>

                                        <v-list-item-subtitle>{{getTimeAgo(i.comment_date)}}
                                            <v-btn x-small @click="openReplies(i.comment_id)" v-if="i.reply_count > 0"
                                                text color="primary">وەڵامەکان
                                                ({{i.reply_count}})</v-btn>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        ❤️
                                        {{i.comment_like_count}}
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-bottom-sheet class="sheet-card-style" scrollable inset v-model="reply_modal">
            <v-card :loading="reply_loading" class="sheet-card-style">
                <v-card-title primary-title>
                    وەڵامەکان
                    <v-spacer></v-spacer>
                    <v-btn icon @click="reply_modal = false">
                        <v-icon color="red">fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-list three-line outlined class="rounded-lg">
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-img :src="comment.user_profile_picture" v-if="comment.user_profile_picture"></v-img>
                                <v-avatar size="100" v-else color="primary" class="white--text">
                                    <span v-if="comment.user_display_name">{{comment.user_display_name[0]}}</span>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <h1>{{comment.user_display_name}}</h1>
                                </v-list-item-title>
                                <span>
                                    {{comment.comment_text}}
                                </span>

                                <v-list-item-subtitle>{{getTimeAgo(comment.comment_date)}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                ❤️

                                {{comment.comment_like_count}}
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list three-line class="mx-5">
                        <v-list-item v-for="(i, index) in comment.replies" :key="index">
                            <v-list-item-avatar>
                                <v-img :src="i.user_profile_picture" v-if="i.user_profile_picture"></v-img>
                                <v-avatar size="100" v-else color="primary" class="white--text">
                                    {{i.user_display_name[0]}}
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <h1>{{i.user_display_name}}</h1>
                                </v-list-item-title>
                                <span>
                                    {{i.reply_text}}
                                </span>

                                <v-list-item-subtitle>{{getTimeAgo(i.reply_date)}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>
<script>
    import axios from 'axios'
    import ItemLoading from '../components/ItemLoading.vue'
    import ErrorPage from '../components/ErrorPage.vue'
    import NotFoundPage from '../components/NotFoundPage.vue'
    import moment from 'moment'
    export default {
        components: {
            ItemLoading,
            ErrorPage,
            NotFoundPage,
        },
        data: () => ({
            item: {},
            loading: true,
            not_found: false,
            err_page: false,
            reply_modal: false,
            comment: {},
            reply_loading: false,
        }),
        mounted() {
            this.$vuetify.theme.dark = false
            this.$vuetify.rtl = true
            this.getItem()
        },
        methods: {
            truncateText(text, length) {
                if (text.length > length) {
                    return {
                        text: text.substring(0, length) + '...',
                        more: true
                    }
                } else {
                    return {
                        text: text,
                        more: false
                    }
                }
            },
            replaceSpaceWithUnderScore(text) {
                return text.replace(/\s/g, '_')
            },
            openReplies(comment_id) {
                this.reply_modal = true
                this.reply_loading = true
                axios.post(`/comments/readOne.php`, {
                    comment_id: comment_id
                }).then(res => {
                    this.comment = res.data.row
                    this.reply_loading = false
                }).catch(err => {
                    console.log(err)
                })
            },
            getTimeAgo(date) {
                moment.locale('ku')
                return moment(date).fromNow()
            },
            getItem() {
                this.loading = true
                axios.post('/items/readOne.php', {
                        item_id: this.$route.params.id,
                        comments: 1001,
                    })
                    .then(response => {
                        console.log(response);
                        if (response.data.status == 'ok') {
                            let item = response.data.row
                            if (item) {
                                this.item = response.data.row
                            } else {
                                this.not_found = true
                            }
                        } else {
                            this.err_page = true
                        }
                        this.loading = false
                    })
                    .catch(error => {
                        this.loading = false
                        this.err_page = true
                        console.log(error)
                    })
            },
            changeToK(n, d) {
                let x = ('' + n).length, p = Math.pow
                d = p(10, d)
                x -= x % 3
                return Math.round(n * d / p(10, x)) / d + " kMGTPE" [x / 3]
            }
        },
    }
</script>
<style>
    .sheet-card-style {
        border-top-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
    }
</style>